@font-face {
    font-family: 'iconfont';  /* Project id 2743346 */
    src: url('//at.alicdn.com/t/font_2743346_fyibrpy7vah.woff2?t=1630032133163') format('woff2'),
    url('//at.alicdn.com/t/font_2743346_fyibrpy7vah.woff?t=1630032133163') format('woff'),
    url('//at.alicdn.com/t/font_2743346_fyibrpy7vah.ttf?t=1630032133163') format('truetype');
}

.iconfont {
    font-family:"iconfont" !important;
    font-size:16px;font-style:normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
}