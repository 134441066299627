




























































































.container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .header {
        height: 60px;
        display: flex;
        position: relative;
        border-bottom: 1px solid #e6e6e6;
        font-size: 16px;
        .logo {
            width: 250px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .right {
            position: absolute;
            right: 0;
            display: flex;
            .username {
                line-height: 60px;
            }
            .logout-btn {
                line-height: 60px;
                cursor: pointer;
                padding: 0 20px;
                &:hover {
                    color: #409eff;
                }
            }
        }
    }
    .content {
        height: 1%;
        flex: 1;
        display: flex;
        ::v-deep .menu-box {
            width: 250px;
            border-right: none;
            .el-menu-item, .el-submenu__title {
                .iconfont {
                    font-size: 18px;
                    margin-right: 6px;
                }
                span {
                    font-size: 16px;
                }
            }
        }
    }
}
::v-deep .right-content {
    width: 1%;
    flex: 1;
    background: #F7F8FC;
    & > .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
::v-deep .menu-wrapper {
    height: 100%;
    border-right: solid 1px #e6e6e6;
    & > .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
