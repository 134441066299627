body, html {
  margin: 0;
  padding: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  color: #333;
  font-weight: 400;
  font-size: 14px;
  overflow: hidden;
}
a {
  color: #333;
  text-decoration: none;
  transition: all .3s;
}
a:focus, a:hover {
  color: #409eff;
}
.clearFix {
  zoom: 1;
}
.clearFix:after {
  content: '';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}
.text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.text-overflow-2 {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.flex {
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.align-center {
  align-items: center;
}
//S table自定义表格
.customTable {
  color: #333;
  font-size: 16px;
  th {
    height: 60px;
    color: #666;
    font-weight: normal;
    background: #F6F6F6;
    border-top: 1px solid #eee;
    &:first-child {
      border-left: 1px solid #eee;
      & > .cell {
        padding-left: 40px;
      }
    }
    &:nth-last-child(2) {
      border-right: 1px solid #eee;
    }
    &.gutter {
      border-right: 1px solid #eee;
    }
  }
  td {
    &:first-child {
      border-left: 1px solid #eee;
      & > .cell {
        padding-left: 40px;
      }
    }
    &:last-child {
      border-right: 1px solid #eee;
    }
  }
  td, th.is-leaf {
    border-color: #eee;
  }
  &:before {
    content: none;
  }
  .el-table__empty-block {
    border-top: none;
    box-sizing: border-box;
  }

}
//分页样式
.pages-center {
  margin: 20px 0 0;
  text-align: center;
}
.el-link + .el-link {
  margin-left: 22px;
}
.input-number-left {
  .el-input__inner {
    text-align: left;
  }
}

// 验证输入框变红
.isError{
  .el-input__inner{
    border-color: #FF0000!important;
  }
}